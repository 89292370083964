/* CSS Document */
/* Styles here will be displayed in the editor according to the regular expressions set up in the ckeditor config.js. 
*/
body 
{
	background-color: White;
	font-family: "ff-netto-web",sans-serif;
	font-size:14px;
	color:#000;
}
.wf-ffnettoweb-n4-active body {
	font-size:16px;
}

html, body{ 
 margin:0; 
 padding:0;  
 line-height:1.5;
 -webkit-font-smoothing: antialiased;
} 
p,input,textarea{ 
margin:0;
padding:0;
}
img
{
	border:none;
	max-width:100%;
}
a {
	color:inherit;
}
p.quote {
	background:#fff;
	color: #000;
	float: right;
	padding: 10px;
	margin-bottom:15px;
	margin-left: 15px;
	font-size: 14px;
	width: 200px;
	line-height:25px;
	border-radius:3px;
	-webkit-box-shadow:  0px 0px 10px 0px #666666;
	box-shadow:  0px 0px 10px 0px #666666;	
	display:block;
	zoom:1;
}
p.quote:before {
	content:'\201C';
}
p.quote:after {
	content: '\201D';
}
p.quote:after,p.quote:before{	
	font-size:72px;
	line-height:4px;
	vertical-align:bottom;
}
.button {
	padding:10px;
	color:#fff;
	background: #4195db url('../assets/menu-bg.gif');
	text-decoration:none;
	display:inline-block;
	border:none;
	margin-top:10px;
}
.button:hover {
	background: #3b579d;
}
h1,h2,h3,h4,h5,h6 {
	color:#3390dd;
	font-weight:normal;
}
h1 {
	font-size:38px;
}
div.row{
	display:table;
	width:100%;
}
div.cell {
	width:50%;
	display:table-cell;
	vertical-align:top;
}

hr {
	height:1px;
	border:0;
	background-color:#e9e8e5;
}
img.float-end {
    float:right;
    margin:0 0 10px 10px;
}
img.float-start {
    float:left;
    margin:0 10px 10px 0;
}
img.staff-portrait {
    float: left; 
    margin-right: 20px; 
    margin-bottom: 230px;
}